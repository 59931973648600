.addAdmission input,select{
    width: 350px !important;
}
.addAdmission form{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.addAdmission .add-img{
    max-width: 350px !important;
}
.addAdmission .add-img input{
    width: 267px !important;
}