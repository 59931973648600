.addImage label {
  font-weight: bold;
}
.addImage input:focus {
  box-shadow: none;
}
.addImage select:focus {
  box-shadow: none;
}
/* td,th{max-width: fit-content;} */
.card table{
    /* border: 1px solid black; */
    line-height: 2.5rem;
}
#imageContainer {
    /* display: none; */
    margin-top: 10px;
    max-width: 10rem;
  }
  #imageContainer img{
    max-width: 10rem;
  }