.update-container {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}
.update-container .img-container {
  width: 150px;
  height: 150px;
}
.update-container .img-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* border-radius: 80px; */
}
.hide-show-btn {
  position: absolute;
  margin-top: -25px;
  margin-left: 320px;
}
.form-password-feild {
  position: relative;
}
.profile-details {
  width: 40%;
}
.profile-details .form-feild span {
  font-size: 12px;
  color: red;
}
