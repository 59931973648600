.manage-category{
    width: 90%;
    margin: auto;
}
.thead-dark tr{
    /* border-radius: 15px; */
}

/* Add this to your CSS or stylesheets */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-btn {
    background-color: #040420;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 25px;
    margin: 0 4px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  .pagination-btn.active {
    /* Styles for the active page number button */
    background-color: white;
    color: #000;
    border-color: #007bff;
  }
  .pagination-btn:hover {
    background-color: #ddd;
  }
  
  .current-page {
    font-size: 18px;
    font-weight: bold;
    margin: 0 10px;
    color: #555;
  }
  .action-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .action-button:focus {
    outline: none;
  }
  /* .table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
} */
table .thead-dark th {
  color: #fff;
  /* background: linear-gradient(45deg, #0d0d42e0, black)!important; */
  background-color: #040420 !important;
  /* border-color: #0d0d42e0; */
}